import React, { useEffect, useRef, useState } from 'react';
import './CreateAirdropForm.css';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import addNavigationImage from '../../assets/images/addnavigation.png';
import plusnavigationImage from '../../assets/images/navigationTitle.png';
import gallerynavigationImage from '../../assets/images/gallerynavigation.png';
import videonavigationImage from '../../assets/images/navigationvideo.png';
import sectionnavigationImage from '../../assets/images/navigationsection.png';
import copyIconImage from '../../assets/images/copyImage.png';
import deleteIconImage from '../../assets/images/deleteImage.png';
import modalImageUpload from '../../assets/images/modalImgupload.png';
import modalUploadedImage from '../../assets/images/modaluploadedImage.png';
import { Form, Modal } from 'react-bootstrap';
import DuplicateForm from './DuplicateForm';
import { v4 as uuidv4 } from 'uuid';
import { Formik } from 'formik';
import { useFormContext } from '../../hooks/contextHook';
import toast from 'react-hot-toast';
import { useCreateAirdropMutation, useGetAirdropByIdQuery, useUpdateAirdropFormMutation } from '../../config/Apibase';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { formatDate } from '../../helpers';
const CreateAirdropForm = () => {
  const [expiryDate, setExpiryDate] = useState('');
  const [show, setShow] = useState(false);
  const { getFormatedForm, updateContent, updateSection, updateFormForEdit } = useFormContext();
  const form = getFormatedForm();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [createAirdrop, { isSuccess, isError }] = useCreateAirdropMutation();
  const [updateAirdrop] = useUpdateAirdropFormMutation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const selectRef = useRef(null);

  const location = useLocation();
  const airdropId = location.state?.airdropId
  const { data: airdrop, isLoading, refetch } = useGetAirdropByIdQuery(airdropId);


  console.log("@765 expiryDate => ", expiryDate, airdrop)
  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };
  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {

    if (airdropId && airdrop) {
      updateFormForEdit(airdrop)
      airdrop?.expirationDate && setExpiryDate(formatDate(airdrop?.expirationDate))
    }

  }, [airdropId, airdrop])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Add Content Div Duplication
  // const [form, setForm] = useState({id: uuidv4()});
  const [sections, setSections] = useState([
    {
      id: uuidv4(),
      title: '',
      formId: '',
      description: '',
    },
  ]);

  const [duplicates, setDuplicates] = useState([{ id: uuidv4(), isRequired: false }]);

  const handleDuplicateClick = (sectionId) => {
    updateContent('', sectionId);
    // const newDuplicate = {
    //   id: uuidv4(),
    // isRequired: false,
    // };

    // setDuplicates((prevDuplicates) => [...prevDuplicates,newDuplicate]);
  };

  const handleCheckboxChange = (index) => {
    setDuplicates((prevDuplicates) => {
      const newDuplicates = [...prevDuplicates];
      newDuplicates[index].isRequired = !newDuplicates[index].isRequired;
      return newDuplicates;
    });
  };
  const handleDeleteDuplicate = (id) => {
    setDuplicates((prevDuplicates) => prevDuplicates.filter((duplicate) => duplicate.id !== id));
  };
  const [airdropTitle, setAirdropTitle] = useState('');
  const [description, setDescription] = useState('');
  const calculateExpirationDate = (selectedDate) => {
    // If the user has selected a date, use it; otherwise, use the current date
    const currentDate = selectedDate ? new Date(selectedDate) : new Date();

    // Add 5 days to the current date
    currentDate.setDate(currentDate.getDate());

    // Format the expiration date as a string (e.g., "YYYY-MM-DD")
    const expirationDate = currentDate.toISOString().split('T')[0];

    return expirationDate;
  };
  const navigate = useNavigate();
  const handleFormSubmit = () => {

    const expirationDateNew = calculateExpirationDate(expiryDate);

    if (airdropId) {
      let airdropClone = { ...airdrop, ...form, expirationDate: expirationDateNew }

      console.log("@765 airdropClone", airdropClone)

      updateAirdrop(airdropClone)
        .then((response) => {
          toast.success('Successfully updated!');
          navigate("/airdrop")
        })
        .catch((error) => {
          toast.error('Sorry your Airdrop is not updated!');
        });
    } else {
      let formCopy = { ...form };
      formCopy.walletAddress = '0x531466aC69A50802dF61D51aC6536bd500b0901b';
      formCopy.status = 'open';
      formCopy.amount = '120';
      formCopy.expirationDate = expirationDateNew;
      createAirdrop(formCopy)
        .then((response) => {
          toast.success('Successfully created!');
          navigate("/airdrop")
        })
        .catch((error) => {
          toast.error('Sorry your Airdrop is not created!');
        });
    }
  };
  const handleFormSectionTitle = (title, section) => {
    updateSection({ ...section, sectionId: section?.id, title });
  };
  const handleFormSectionDescription = (description, section) => {
    updateSection({ ...section, sectionId: section?.id, description });
  };
  const handleFormChange = (index, formData) => {
    // Update the form data in the parent component
    setDuplicates((prevDuplicates) => {
      const newDuplicates = [...prevDuplicates];
      newDuplicates[index].formData = formData;
      return newDuplicates;
    });
  };
  const handleQuillChange = (value, section) => {
    // Update the section's description in the parent component
    handleFormSectionDescription(value, section);
  };
  const handleExpiryDateChange = (event) => {
    setExpiryDate(event.target.value);
  };
  return (
    <div>
      <div className="airdrop_form_bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="form_tabs_spacing">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Col sm={11}>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <Formik initialValues={{}} onSubmit={handleFormSubmit}>
                            {({ handleSubmit }) => (
                              <Form onSubmit={handleSubmit}>
                                {form?.sections?.map((section) => (
                                  <>
                                    <Row>
                                      <Col>
                                        <section key={section?.id}>
                                          <div>
                                            <div className="airdrops_tabs_bg">
                                              <div className="text-end">
                                                <label htmlFor="expiryDate" className="airdrops_home_card_text_p">
                                                  Expiry Date:
                                                </label>
                                                <input
                                                  type="date"
                                                  id="expiryDate"
                                                  name="expiryDate"
                                                  className="expiry_date_input"
                                                  value={expiryDate}
                                                  onChange={handleExpiryDateChange}
                                                />
                                              </div>
                                              <label htmlFor="addairdroptitle"></label>
                                              <input
                                                type="text"
                                                id="addairdroptitle"
                                                placeholder="Airdrops Titles"
                                                name="addairdroptitle"
                                                className="form_title_input_field"
                                                value={section?.title}
                                                onChange={(e) => handleFormSectionTitle(e.target.value, section)}
                                              />
                                              {/* <label htmlFor="adddescription"></label> */}
                                              <ReactQuill
                                                theme='snow'
                                                id="adddescription"
                                                placeholder="Add Description"
                                                name="adddescription"
                                                className="form_input_field border-bottom-0"
                                                value={section?.description}
                                                onChange={(value) => handleQuillChange(value, section)}
                                              // onChange={(e) => handleFormSectionDescription(e.target.value, section)}
                                              />
                                            </div>
                                            {section?.contents?.map((content, index) => (
                                              <DuplicateForm
                                                content={content}
                                                key={content.id}
                                                id={content.id}
                                                index={index}
                                                isRequired={content?.fields[0]?.isRequired}
                                                onCheckboxChange={handleCheckboxChange}
                                                onDelete={handleDeleteDuplicate}
                                                onFormChange={(formData) => handleFormChange(index, formData)}
                                              />
                                            ))}
                                          </div>
                                          <div className="mt-4 d-flex justify-content-end">
                                            <button type="submit" className="apply_for_pool_btn">
                                              Submit
                                            </button>
                                          </div>
                                        </section>
                                      </Col>

                                      <Col sm={1} className="form_buttons_bg">
                                        <Nav variant="pills" className="flex-column">
                                          <div className="form_pills_bg text-center">
                                            <Nav.Item>
                                              <Nav.Link eventKey="first" className='nav_link_navigate'>
                                                <div onClick={() => handleDuplicateClick(section.id)}>
                                                  <img
                                                    src={addNavigationImage}
                                                    className="py-3"
                                                    width="30px"
                                                    alt="navigation_plus_icon"
                                                  />
                                                </div>
                                              </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                              <Nav.Link eventKey="second" className='d-none'>
                                                <div>
                                                  <img
                                                    src={plusnavigationImage}
                                                    className="navigation_image_space"
                                                    width="25px"
                                                    alt="navigation_title_icon"
                                                  />
                                                </div>
                                              </Nav.Link>
                                              <Nav.Link eventKey="third" className='d-none'>
                                                <div onClick={handleShow}>
                                                  <img
                                                    src={gallerynavigationImage}
                                                    className="navigation_image_space"
                                                    width="30px"
                                                    alt="navigation_gallery_icon"
                                                  />
                                                </div>
                                              </Nav.Link>
                                              <Nav.Link eventKey="fourth" className='d-none'>
                                                <div>
                                                  <img
                                                    src={videonavigationImage}
                                                    className="navigation_image_space"
                                                    width="31px"
                                                    alt="video_gallery_icon"
                                                  />
                                                </div>
                                              </Nav.Link>
                                              <Nav.Link eventKey="fifth" className='d-none'>
                                                <div>
                                                  <img
                                                    src={sectionnavigationImage}
                                                    className="navigation_image_space mb-4 pb-3"
                                                    width="30px"
                                                    alt="section_gallery_icon"
                                                  />
                                                </div>
                                              </Nav.Link>
                                            </Nav.Item>
                                          </div>
                                        </Nav>
                                      </Col>
                                    </Row>
                                  </>
                                ))}
                              </Form>
                            )}
                          </Formik>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <div className="airdrops_tabs_bg">
                            <h5 className="airdrop_tabs_h"> Airdrops Titles </h5>
                            <label htmlFor="adddescription"></label>
                            <input
                              type="text"
                              id="adddescription"
                              placeholder="Add Description"
                              name="adddescription"
                              className="form_input_field"
                            />
                            <div className="border-bottom-form-hr"></div>
                            <div className="d-flex justify-content-end pt-2">
                              <div className="line_vr_bar me-4 mt-1"></div>
                              <div>
                                {/* <img src={copyIconImage} alt="" className="me-4 icon_cursor" width="25px"></img> */}
                                <img src={deleteIconImage} alt="" className="icon_cursor" width="25px"></img>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                          <div>
                            <div className="selected_image_preview_bg">
                              <h5 className="image_title_h">Image Title</h5>
                              <div className="border-bottom-form-hr mb-2"></div>
                              <div className="row">
                                <div className="col-lg-6 col-md-6 col-12">
                                  <img src={modalUploadedImage} alt="Uploaded Image" className="img-fluid"></img>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end pt-2">
                                <div className="line_vr_bar me-4 mt-1"></div>
                                <div>
                                  {/* <img src={copyIconImage} alt="" className="me-4 icon_cursor" width="25px"></img> */}
                                  <img src={deleteIconImage} alt="" className="icon_cursor" width="25px"></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourth">Fourth tab content</Tab.Pane>
                        <Tab.Pane eventKey="fifth">Fifth tab content</Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>

                  {/* Image Upload Modal  */}
                  <Modal show={show} onHide={handleClose} size="lg" centered>
                    <div className="px-lg-5 px-3 pb-3">
                      <div className="d-flex justify-content-center pt-3">
                        <div className="top_bar_effect"></div>
                      </div>
                      <div className="text-center">
                        <h5 className="insert_image_h pt-4">Insert Image</h5>
                      </div>
                      <div className="text-center mt-3 mb-3">
                        <button className="image_upload_modal_btn image_upload_modal_btn_active">Upload</button>
                        <button className="image_upload_modal_btn ms-3">Google Drive</button>
                      </div>
                      <div className="border-bottom-form-hr mb-3"></div>
                      <div className="text-center image_upload_space">
                        <img src={modalImageUpload} alt="modal_img_upload" width="140px"></img>
                      </div>
                      <div className="d-flex justify-content-center">
                        <button className="browse_modal_btn">Browse</button>
                      </div>
                      <div className="text-center pt-3 pb-3">
                        <p className="drag_a_file_p mb-0">Or drag a file here</p>
                      </div>
                      <div className="border-bottom-form-hr mb-3"></div>
                    </div>
                  </Modal>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAirdropForm;
