import React, { useEffect, useState } from 'react';
import './Signin.css';
import Navbar from '../../components/Navbar/Navbar';
import Airdropdatatable from '../../components/Airdropdatatable/Airdropdatatable';
import toast from 'react-hot-toast';
import { useSignInMutation } from '../../config/Apibase';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';

const Airdropdata = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [signature, setSignature] = useState(null);
  const [timestamp, setTimestamp] = useState(null);
  const [token, setToken] = useState({});
  const [signIn, data] = useSignInMutation();
  function OnHandleSignIn() {
    if (window.ethereum) {
      window.ethereum.request({ method: 'eth_requestAccounts' }).then((accounts) => {
        try {
          if (isOpen) {
            setIsOpen(false);
            const currentAccount = accounts[0];
            // Construct the message
            const timestamp = Date.now();
            setTimestamp(Date.now());
            const message = 'AirDrop' + timestamp;
            // Sign the message
            window.ethereum
              .request({
                method: 'personal_sign',
                params: [message, currentAccount],
              })
              .then((response) => {
                setSignature(response);
                const data = signIn({ timestamp: timestamp, signature: response });
                data
                  .then((response) => {
                    localStorage.setItem('token', JSON.stringify(response.data.tokens));
                    localStorage.setItem('walletAddress', currentAccount)
                    setToken(response.data.tokens);
                    window.location.reload();
                  })
                  .catch((error) => {
                    toast.error('Error signing message:', error);
                  });
              });
          }
        } catch (error) {
          toast.error('Error signing message:', error);
          console.log(error, ">>Error signing message")
          return; // Exit the function if there's an error
        }
      });
    } else {
      toast.error('MetaMask is not installed');
    }
  }
  // const res = signIn();
  useEffect(() => {}, []);
  console.log(token, ">>Chekcing token")
  return (
    <div>
      <Navbar />
      <div className='sign_in_btn'>
        <div className='wrapper'>
        <div className='d-flex justify-content-center'>
        <button className="submit_airdrop_form_btn" onClick={OnHandleSignIn}>
          Sign In
        </button>
        </div>
        </div>
      </div>
      {/* <Footer/> */}
      {/* <Airdropdatatable /> */}
    </div>
  );
};

export default Airdropdata;
