import React, { useEffect, useState } from 'react';
import './Navbar.css';
import { Link, useLocation } from 'react-router-dom';
import { useSignInMutation } from '../../config/Apibase';
import blacklogo from '../../assets/images/coloredlogo.png';
import { FaPlus } from 'react-icons/fa6';
const NewNav = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };
  const [token, setToken] = useState({});

  const [signIn, data] = useSignInMutation();
  // const res = signIn();
  useEffect(() => {
    setToken(localStorage.getItem('token'));
  }, []);
  const location = useLocation();
  const pathname = location?.pathname;
  return (
    <div className="sticky-top">
      <nav
        className={`navbar navbar-expand-lg ${
          pathname === '/' || pathname === '/users-dashboard' ? 'nav_bg' : 'nav_bg_white'
        } `}
      >
        <div className="container">
          <a className="navbar-brand" href="https://ideaology.io/" target="_blank" rel="noreferrer">
            {pathname === '/' || pathname === '/users-dashboard' ? (
              <img src="https://ideaology.io/imgs/logo/logo-white.svg" height="41px" />
            ) : (
              <img src={blacklogo} height="50px" />
            )}
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {pathname === '/' || pathname === '/users-dashboard' ? (
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0 position-relative">
                <div className="highLightLaunchNew d-none d-lg-block">
                  <span className="highLightTextNew">New</span>
                </div>
                <div className="highLightLaunch d-none d-lg-block">
                  <span className="highLightText">New Launched</span>
                </div>
                <li className="nav-item dropdown" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                  <Link className="nav-link dropdown-toggle" to="/" role="button" aria-expanded="false">
                    Games
                  </Link>
                  <ul className={`dropdown-menu py-lg-4 py-md-3 py-3 px-1 ${isDropdownOpen ? 'show' : ''}`}>
                    <li>
                      <a
                        className="dropdown-item pb-3"
                        target="_blank"
                        rel="noreferrer"
                        href="https://play.google.com/store/apps/details?id=com.mms.savethepuppies.rescuedog.puppygames"
                      >
                        <img src="https://ideaology.io/games/dog.png" className="rounded-2" alt="Save the Doge"></img>
                        <span className="ps-3">Save the Doge</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        target="_blank"
                        rel="noreferrer"
                        href="https://play.google.com/store/apps/details?id=com.IdeaologyStudio.RealPoolBallBilliardGames"
                      >
                        <img
                          src="https://ideaology.io/games/pool.png"
                          className="rounded-2"
                          alt="Pool Master Billiard"
                        ></img>
                        <span className="ps-3">Pool Master Billiard</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="https://workaspro.com/" target="_blank" rel="noreferrer">
                    WorkAsPro
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="https://www.manialands.com/" target="_blank" rel="noreferrer">
                    Manialands
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="https://ideaology-1.gitbook.io/ideaology-1/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Docs
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="https://ideaology.io/voting" target="_blank" rel="noreferrer">
                    Voting
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="https://ideaology.io/voting" target="_blank" rel="noreferrer">
                    Get IDEA
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="https://ideaology.io/swap" target="_blank" rel="noreferrer">
                    Swap IDEA
                  </Link>
                </li>
              </ul>
            ) : (
              ''
            )}
            {token && window.location.pathname !== '/' && !pathname.includes('users-dashboard') ? (
              <>
                <Link to="/all-airdrops" className="apply_for_pool_btn text-decoration-none ms-auto me-3">
                  All Airdrops
                </Link>
                {pathname.includes('create-airdrop') ? (
                  ''
                ) : (
                  <>
                    <Link to="/create-airdrop" className="apply_for_pool_btn text-decoration-none">
                      <FaPlus className="me-2" /> Create Airdrop
                    </Link>
                  </>
                )}
              </>
            ) : (
              <Link to="/" className="apply_pool_btn text-decoration-none">
                AirDrop
              </Link>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NewNav;
