import React, { useEffect, useRef } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import vecterimglogo from '../../assets/images/vecter-green.png';
import vecterimgbluelogo from '../../assets/images/vecter-blue.png';
import vecterimgredlogo from '../../assets/images/vecter-red.png';
import $ from 'jquery';
import 'datatables.net-bs5';
import handleCreateHanding from '../../helpers/dataFormHeaderBuilder';
import { useGetClaimsByAirdropQuery } from '../../config/Apibase';
import TableRows from './TableRows';


export function formatTimestamp(timestamp) {
  const dateObj = new Date(timestamp);

  // Format date
  const optionsDate = { year: '2-digit', month: 'short', day: 'numeric' };
  const formattedDate = dateObj.toLocaleDateString('en-US', optionsDate);

  // Format time
  const optionsTime = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
  const formattedTime = dateObj.toLocaleTimeString('en-US', optionsTime);

  return {
    date: formattedDate,
    time: formattedTime,
  };
}
const Datatable = ({ data: airdrop, status }) => {
  const tableRef = useRef(null);
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    setPage(1)
  }, [status])


  const { data: airDrop, refetch, isLoading } = useGetClaimsByAirdropQuery({ id: airdrop?._id, page, limit: 5, status })

  const isDataAvailable = airDrop?.claims?.length > 0


  return (<>
    <div className='table_overflow_x_status table_overflow_x_scroll'>
      <table id='example' className='table table-striped' ref={tableRef}>
        <thead>
          <tr>
            <th>Location</th>
            <th>IP Address</th>
            <th style={{ minWidth: "110px" }}>Date</th>
            <th style={{ minWidth: "110px" }}>Time</th>
            {handleCreateHanding(airdrop)?.map((item, index) => (
              <th key={index}>{item}</th>
            ))}
            <th >Wallet Address</th>
            <th >Status</th>
          </tr>
        </thead>
        <tbody>
          {
            airDrop?.claims?.map((item, index) => (
              <TableRows key={item?._id} item={item} index={index} airdrop={airdrop} />
            ))}
        </tbody>
      </table>
      {!isDataAvailable && <span>No Data Available!</span>}
    </div>
    <div className='d-flex justify-content-end'>
      <Stack spacing={2} display={isDataAvailable ? "" : "none"} className='pt-3'>
        <Pagination count={airDrop?.totalPages} page={page} onChange={handleChange} variant="outlined" color="primary" />
      </Stack>
    </div>
  </>
  );
};

export default Datatable;
