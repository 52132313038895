import React, { useEffect, useState } from 'react';
import './Airdrophomecard.css';
import Card from './Card';
import { IoSearchSharp } from 'react-icons/io5';
import { GoPlus } from 'react-icons/go';
import { useGetAirdropsQuery, useSearchAirdropsQuery, useUpdateClaimMutation } from '../../config/Apibase';
import { CustomFormikProvider } from '../../hooks/contextFormikHook';
import { Modal } from 'react-bootstrap';
import { RxCross2 } from 'react-icons/rx';
import Datatable from '../Airdropdatatable/Datatable';
import { Link } from 'react-router-dom';
const Airdrophomecard = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showSearch, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchResponse, setSearchResponse] = useState({});
  const search = useSearchAirdropsQuery({ search: searchValue }, { skip: !showSearch });

  const [previousResponse, setPreviousResponse] = useState(null);
  const { data, refetch, isLoading } = useGetAirdropsQuery({ page: currentPage });
  const [totalPages, setTotalPages] = useState(0);
  useEffect(() => {
    if (data) {
      setPreviousResponse([...(previousResponse || []), ...data.airdrops]);
      setTotalPages(data.totalPages);
      const container = document.querySelector('.container');
      container.scroll({ behavior: 'smooth', block: 'end' });
    }
  }, [data]);
  useEffect(() => {
    if (search.isSuccess) setSearchResponse(search.data);
    console.log(searchResponse, 'searchResponse');
  }, [search]);
  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };
  const handleMoreClick = () => {
    setCurrentPage(currentPage + 1);
    refetch(); // Trigger refetch with the new page value
  };

  const handleCloseSearch = () => setShowSearch(false);
  // const handleShowSearch = () => {
  //   // const { data, refetch, isLoading } = useSearchAirdropsQuery({ search: searchValue });
  //   setShowSearch(true);
  // };
  const handleShowSearch = () => {
    setShowSearch(true);
    // search.refetch({ search: searchValue });
  };
  const [showDashboard, setShowDashboard] = useState(false);

  const handleCloseDashboard = () => setShowDashboard(false);
  const handleShowDashboard = () => setShowDashboard(true);
  const walletAddressStored = localStorage.getItem("currentAccount")
  console.log("prev => ", previousResponse)
  return (
    <CustomFormikProvider>
      <div className="cards_top_height">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div>
                <div className="d-flex justify-content-end pe-lg-3 pt-2 d-none">
                  <div className="w-25">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control search_input_field"
                        placeholder="Search..."
                        aria-label="Search"
                        aria-describedby="search-btn"
                        onChange={handleSearchInputChange}
                      />
                      <span className="input-group-text search_btn" id="search-btn" onClick={handleShowSearch}>
                        <IoSearchSharp className="search_icon" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Search approve reject modal  */}

          <Modal show={showSearch} onHide={handleCloseSearch} centered>
            <div className="px-3 py-3 modal_bg_color">
              <div className="d-flex justify-content-end">
                <RxCross2 onClick={handleCloseSearch} className="cross_cursor" />
              </div>
              <div className="d-flex flex-column align-items-center">
                <p className="approve_reject_airdrop mb-3">Airdrop Status</p>
                {searchResponse?.airdrops?.map((item, index) => (
                  <div key={index} className="d-flex justify-content-between mb-2">
                    <div>{`${item.walletAddress}:: `} </div>
                    <div> {` ${item.status}`}</div>
                  </div>
                ))}
              </div>
            </div>
          </Modal>
          {/* {!walletAddressStored ?  */}
          <div className="row">
            {previousResponse?.map((item, index) => (
              <Card item={item} key={index} />
            ))}
            <div className="d-flex justify-content-center pt-5">
              {currentPage < totalPages &&
                (isLoading ? (
                  <div className="loading">Loading...</div>
                ) : (
                  <button className="more_home_card_btn" onClick={handleMoreClick}>
                    <GoPlus fontSize={22} className="me-1" /> More
                  </button>
                  // airdrops.map((item, index) => <Card item={item} key={index} />)
                ))}
            </div>
          </div>
          {/* :""
} */}
        </div>
      </div>
    </CustomFormikProvider>
  );
};

export default Airdrophomecard;
