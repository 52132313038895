import config from './config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const storedToken = localStorage.getItem('token');
const parsedToken = storedToken ? JSON.parse(storedToken) : null;
const accessToken = parsedToken?.access?.token;

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: config.api.baseUrl }),
  endpoints: (builder) => ({
    createAirdrop: builder.mutation({
      query: (data) => ({
        url: '/api/v1/airdrop',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['AirdropList', 'Airdrop'],
    }),
    getAirdrops: builder.query({
      query: (data) => ({
        url: '/api/v1/airdrop',
        method: 'GET',
        // body: data,
        params: {
          page: data?.page || 1, // Default to page 1 if not provided
          limit: data?.limit || 7, // Default to limit 7 if not provided
          sortOrder: data?.sortOrder || 'asc', // Default to ascending order
          sortBy: data?.sortBy || 'createdAt', // Default to sorting by createdAt
          isClaim: data?.isClaim || null,
          walletForClaim: data?.walletForClaim || null,
          shouldActive: data?.shouldActive || false
        },
      }),
      providesTags: ['AirdropList'],
    }),
    searchAirdrops: builder.query({
      query: (data) => ({
        url: '/api/v1/airdrop',
        method: 'GET',
        // body: data,
        params: {
          page: data?.page || 1, // Default to page 1 if not provided
          limit: data?.limit || 7, // Default to limit 7 if not provided
          sortOrder: data?.sortOrder || 'asc', // Default to ascending order
          sortBy: data?.sortBy || 'createdAt', // Default to sorting by createdAt
          search: data?.search || '', // Default to sorting by createdAt
        },
      }),
    }),
    getAirdrop: builder.query({
      query: (data) => ({
        url: '/api/v1/airdrop',
        method: 'GET',
        body: data,
      }),
    }),
    getClaimsByWallet: builder.query({
      query: (data) => ({
        url: `/api/v1/claimsbywallet/${data.walletAddress}`,  // Adjust the URL if needed
        method: 'GET',
        headers: {
          Authorization: accessToken,
        },
      }),
    }),
    getClaimsByAirdrop: builder.query({
      query: (data) => ({
        url: `/api/v1/claims/airdrop/${data.id}`,  // Adjust the URL if needed
        method: 'GET',
        params: {
          page: data?.page || 1, // Default to page 1 if not provided
          limit: data?.limit || 7, // Default to limit 7 if not provided
          sortOrder: data?.sortOrder || 'asc', // Default to ascending order
          sortBy: data?.sortBy || 'createdAt', // Default to sorting by createdAt
          status: data?.status || "pending"
        },
        // headers: {
        //   Authorization: accessToken,
        // },
      }),
      providesTags: ["ClaimsByAirdrop"]
    }),
    updateAirdropForm: builder.mutation({
      query: (data) => ({
        url: data?._id ? `/api/v1/airdrop/${data?._id}` : "",
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['AirdropList', 'Airdrop'],
    }),
    deleteAirdropForm: builder.mutation({
      query: (id) => ({
        url: id ? `/api/v1/airdrop/${id}` : "",
        method: 'DELETE',
      }),
      invalidatesTags: ['AirdropList', 'Airdrop'],
    }),
    getAirdropById: builder.query({
      query: (id) => ({
        url: id ? `/api/v1/airdrop/${id}` : "",
        method: 'GET',
      }),
      providesTags: ['Airdrop'],
    }),
    getClaimAll: builder.query({
      query: (data) => ({
        url: '/api/v1/claims',
        method: 'GET',
        params: {
          page: data?.page || 1, // Default to page 1 if not provided
          limit: data?.limit || 7, // Default to limit 7 if not provided
          sortOrder: data?.sortOrder || 'asc', // Default to ascending order
          sortBy: data?.sortBy || 'createdAt', // Default to sorting by createdAt
        },
      }),
    }),
    signIn: builder.mutation({
      query: (data) => ({
        url: '/api/v1/user/signin',
        method: 'POST',
        body: data,
      }),
    }),
    login: builder.mutation({
      query: (data) => ({
        url: '/api/v1/claimuser/login',
        method: 'POST',
        body: data,
      }),
    }),
    signup: builder.mutation({
      query: (data) => ({
        url: '/api/v1/claimuser/create',
        method: 'POST',
        body: data,
      }),
      transformResponse: (response) => {
        return response?.data;
      }
    }),
    createClaim: builder.mutation({
      query: (data) => {
        return {
          url: `/api/v1/claims`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ["Airdrop", "AirdropList"]
    }),
    updateClaim: builder.mutation({
      query: (data) => {
        let id_ = data.id;
        delete data.id;
        return {
          url: `/api/v1/claims/${id_}`,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ["ClaimsByAirdrop", 'AirdropList', 'Airdrop']
    }),
    generateSignature: builder.mutation({
      query: (data) => {
        return {
          url: `/api/v1/airdrop/generate/signature`,
          method: 'POST',
          body: data,
          headers: {
            Authorization: accessToken,
          },
        };
      },
    }),
  }),
});
export const {
  useGetClaimsByAirdropQuery,
  useCreateAirdropMutation,
  useLoginMutation,
  useSignupMutation,
  useGetAirdropsQuery,
  useDeleteAirdropFormMutation,
  useSearchAirdropsQuery,
  useGetAirdropQuery,
  useGetAirdropByIdQuery,
  useSignInMutation,
  useUpdateAirdropFormMutation,
  useGetClaimAllQuery,
  useCreateClaimMutation,
  useUpdateClaimMutation,
  useGenerateSignatureMutation,
  useGetClaimsByWalletQuery
} = api;
