import React from "react";
import { Button, Container, makeStyles } from "@material-ui/core";
import heroPNG from "../../assets/images/hero.png";
import crossRightPNG from "../../assets/images/cross-right.png";
import certikSVG from "../../assets/images/certik.svg";
import NewNav, { Header } from "./NewNav";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '500px',
    background: '#081015',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    position: 'relative',
    top:"-70px"
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    overflowX: 'hidden',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      overflowX:"visible"
    },
  },
  heroContainer: {
    height: '500px',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '100vw',
    position: 'relative',
    left: '-70px',
    overflowY: 'hidden',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      left: '0px',
      display: 'flex',
      justifyContent: 'center',
      marginTop: '52px',
    },
  },
  heroImg: {
    display: 'block',
    width: '850px',
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '382px',
    },
  },
  heroText: {
    zIndex: 10,
  },
  contentText: {
    width: '676px',
    fontSize: '48px',
    fontWeight: 700,
    color: '#FFFFFF',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: '36px',
      lineHeight: '32px',
      width: '450px',
      fontWeight: 700,
      marginTop: '80px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 'normal',
      width: '271px',
      marginTop: '80px',
    },
  },
  buttonWrapper: {
    marginTop: '24px',
    display: 'flex',
  },
  gap: {
    width: '16px',
  },
  poolButton: {
    background: '#FFFFFF',
    color: '#1C2535',
    width: '139px',
    height: '40px',
    display:'none',
    fontSize: '16px',
  },
  communityButton: {
    background: '#FD4975',
    color: '#FFFFFF',
    width: '150px',
    height: '40px',
    fontSize: '14px',
    display:'none',
    '&:hover': {
      backgroundColor: '#FE87A4',
    },
  },
  linesLeft: {
    backgroundImage: 'url(https://ideaology.io/imgs/landing/lines-1.svg)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '663px',
    height: '500px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  linesRight: {
    backgroundImage: 'url(https://ideaology.io/imgs/landing/lines-2.svg)',
    position: 'absolute',
    top: 0,
    right: 0,
    width: '796px',
    height: '500px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  crossLeft: {
    backgroundImage: 'url(https://ideaology.io/imgs/landing/cross-left.svg)',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '133px',
    height: '76px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  crossRight: {
    backgroundImage: `url(${crossRightPNG})`,
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '133px',
    height: '50px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  auditWrapper: {
    position: 'relative',
  },
  certikWrapper: {
    position: 'absolute',
    marginTop: '45px',
    color: 'white',
    fontSize: '14px',
    lineHeight: '24px',
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      width: '294px',
    },
  },
  heroCertikImg: {},
  break: {
    flexBasis: '100%',
    height: 0,
    [theme.breakpoints.down('xs')]: {
      flexBasis: '8px',
    },
  },
}));

const Navbar = (props) => {
  const classes = useStyles();

  const onPools = () => {};
  const onJoinCommunity = () => {};
  const location = useLocation();
  const pathname = location?.pathname
  return (
    <div>
      <NewNav/>
      {pathname === "/" || pathname === "/users-dashboard" ?
    <div className={classes.root}>
      <div className={classes.linesLeft}></div>
      <div className={classes.linesRight}></div>
      <Container className={classes.container} maxWidth="lg">
        <div className={classes.heroText}>
          <div className={classes.contentText}>
            Support Bright Ideas Through Decentralized Crowdfunding
          </div>
          <div className={classes.buttonWrapper}>
            <Button
              className={classes.communityButton}
              disableElevation
              href={`https://ido.ideaology.io/apply`}
              onClick={onJoinCommunity}
              variant="contained"
            >
              Apply for IDO
            </Button>
            <div className={classes.gap}></div>
            <Button
              className={classes.poolButton}
              disableElevation
              href={`https://ido.ideaology.io/pools`}
              onClick={onPools}
              variant="contained"
            >
              Pools
            </Button>
          </div>
          <div className={classes.auditWrapper}>
            <a
              href="https://www.certik.org/projects/ideaology"
              rel="noreferrer"
              target="_blank"
            >
              <div className={classes.certikWrapper}>
                <span>Audited by:</span>
                <div className={classes.break}></div>
                <img
                  alt="certik"
                  className={classes.heroCertikImg}
                  src={certikSVG}
                />
              </div>
            </a>
          </div>
        </div>
        <div className={classes.heroContainer}>
          {/* <img alt="hero" className={classes.heroImg} src={heroPNG} /> */}
        </div>
      </Container>
      <div className={classes.crossLeft}></div>
      <div className={classes.crossRight}></div>
    </div>
    :""
    }
    </div>
  );
};

export default Navbar;
