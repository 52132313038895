import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './Airdrophomecard.css';
import { Modal } from 'react-bootstrap';
import locationiconimg from '../../assets/images/locationiconimg.png';
import thanksimg from '../../assets/images/thanksimg.png';
import * as Yup from 'yup';
import ClientFormBuilders from '../../helpers/ClientFormBuilder';
import { useCustomFormikContext } from '../../hooks/contextFormikHook';
import Timer from './Timer';
import { getGeoLocation } from '../../helpers/getGeoLocation';
import ViewWalletaddress from '../View/ViewWalletaddress';
import processFormData from '../../helpers/formDataBuilder';
import { useCreateClaimMutation, useLoginMutation, useSignInMutation, useSignupMutation } from '../../config/Apibase';
import { RiShareFill, RiWhatsappFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedin, FaTelegram, FaTwitter, FaWhatsapp } from 'react-icons/fa6';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';

function capitalizeFirstLetter(string) {
  if (!string) return string; // return the string if it's empty or undefined

  return string.charAt(0).toUpperCase() + string.slice(1);
}

const UserdashboardCard = ({ item }) => {
  const [showFullContent, setShowFullContent] = useState(false);
  const [userlocation, setUserLocation] = useState({ ipaddress: '', location: {} });
  const [currentContent, setCurrentContent] = useState(null);
  // Convert the expiration date string to a Date object
  const expirationDateTime = new Date(item?.expirationDate);
  const currentDate = new Date();
  const isExpired = expirationDateTime <= currentDate;
  const [createClaim] = useCreateClaimMutation();
  const { formik, isSubmitting, handleSubmitCallbackFunc, onSubmitCallback, resetClaimForm } = useCustomFormikContext();
  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };
  const [show, setShow] = useState(false);



  const handleClose = () => setShow(false);
  const [showSignup, setShowSignup] = useState(false);

  const handleCloseSignup = () => setShowSignup(false);
  const handleShowSignup = () => setShowSignup(true);
  const aftersignup = () => {
    handleCloseSignup();
    setShowclaimdrop(true);
  };

  // Connect Metamask For Signin
  const [isOpen, setIsOpen] = useState(true);
  const [signature, setSignature] = useState(null);
  const [timestamp, setTimestamp] = useState(null);
  const [token, setToken] = useState({});

  const [login] = useLoginMutation();
  const [signIn, data] = useSignInMutation();
  const [signup] = useSignupMutation();

  // Login
  async function OnHandleSignIn() {
    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

        if (isOpen) {
          setIsOpen(false);
          const currentAccount = accounts[0];
          localStorage.setItem('currentAccount', currentAccount);
          const timestamp = Date.now();
          setTimestamp(Date.now());
          const message = 'AirDrop' + timestamp;
          const response = await window.ethereum.request({
            method: 'personal_sign',
            params: [message, currentAccount],
          });
          const walletAddress = currentAccount;
          const loginResponse = await login({ walletAddress });
          if (loginResponse?.data?.message === 'User not register') {
            handleShowSignup(true);
          } else {
            setShowclaimdrop(true);
          }
        }
      } else {
        toast.error('MetaMask is not installed');
      }
    } catch (error) {
      toast.error('Error signing message:', error);
    }
  }

  const handleShow = (item) => {
    setCurrentContent(item);
    setShowclaimdrop(true);
    resetClaimForm()
  };
  const [showclaimdrop, setShowclaimdrop] = useState(false);

  const transformData = (data, airdropContents) => {

    const section = {
      contents: [],
    };

    for (const key in data) {
      const [type, _, index] = key.split('-')

      const field = airdropContents[parseInt(index) - 1]?.fields[0]

      // console.log("@33 => ", { key, type, index, field, airdropContents })
      if (type) {
        const content = {};
        content["question"] = field?.question;
        content["value"] = data[key];
        content["type"] = field?.type
        section.contents.push(content);
      }
    }

    return [section];
  };

  function extractCheckboxPairs(obj) {
    const checkboxPairs = {};
    const arrayOfFiles = [];
    for (const [key, value] of Object.entries(obj)) {
      if (key.includes('image')) {
        checkboxPairs[key] = value;
        arrayOfFiles.push({ [key]: value })
      }
    }
    return checkboxPairs
  }
  const handleCloseclaimdrop = () => {
    setShowclaimdrop(false);
    // resetClaimForm()
  }

  const handleSubmit = async (values) => {
    const cloneValues = { ...values };

    const files = extractCheckboxPairs(values);

    for (const [key, value] of Object.entries(cloneValues)) {
      if (key.includes('image') || key.includes('walletAddress') || key.includes('airdrop')) {
        delete cloneValues[key]
      }
    }

    const transformedData = transformData(cloneValues, item?.sections[0]?.contents);


    const resobj = await getGeoLocation();
    let country = resobj?.country;
    let ipaddress = await getIpAddress();
    const storedWalletAdress = localStorage.getItem('currentAccount');
    const form = {
      country,
      ipaddress,
      walletAddress: storedWalletAdress,
      status: 'pending',
      airdrop: values?.airdrop,
      sections: transformedData,
    };

    let data = processFormData(form);

    if (files) {
      for (const key in files) {
        const [type, _, index] = key.split('-')

        const field = item?.sections[0]?.contents[parseInt(index) - 1]?.fields[0]

        data.append(key, files[key]);
        data.append(key + "-question", field?.question);
      }
    }

    // console.log("@33 => ", { form, transformData, values, files })

    createClaim(data)
      .then((response) => {
        if (response?.error) {

          const message = response?.error?.data?.message || "";

          if (message?.toLowerCase().includes("user")) {
            toast.error('User already has claimed this airdrop!');
          }

          console.error("error creating claim => ", response?.error)
          toast.error('Something went wrong creating claim!');
        } else {
          setShowthanksclaimdrop(true);

        }
      })
      .catch((error) => {
        console.error("error creating claim => ", error);
      }).finally(() => {
        resetClaimForm()
      });
  };


  useEffect(() => {
    if (!onSubmitCallback) {
      handleSubmitCallbackFunc(() => handleSubmit);
    }
  }, []);

  async function getIpAddress() {
    try {
      // Make a GET request to the ipify API
      const response = await fetch('https://api.ipify.org/?format=json');

      // Check if the request was successful (status code 200)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON response
      const data = await response.json();

      // Extract and use the IP address from the response
      const ipAddress = data.ip;
      return ipAddress;
    } catch (error) {
      console.error('Error:', error.message);
    }
  }
  async function getLocationWithIpAddress(ipAddress) {
    // try {
    //   // const encodedParams = new URLSearchParams();
    //   // encodedParams.set('ip', ipAddress);
    //   // encodedParams.set('reverse-lookup', 'checked');

    //   // const url = 'https://community-neutrino-ip-info.p.rapidapi.com/ip-info';

    //   // const response = await fetch(url, {
    //   //   method: 'POST',
    //   //   headers: {
    //   //     'Content-Type': 'application/x-www-form-urlencoded',
    //   //     'X-RapidAPI-Key': 'f219192224mshe90d3c2a50e1f0ap1ab14fjsna04017a9e463',
    //   //     'X-RapidAPI-Host': 'community-neutrino-ip-info.p.rapidapi.com',
    //   //   },
    //   //   body: encodedParams,
    //   // });

    //   // if (!response.ok) {
    //   //   throw new Error(`HTTP error! Status: ${response.status}`);
    //   // }

    //  // const data = await response.json();
    //   return true;
    // } catch (error) {
    //   console.error('Error:', error.message);
    // }
    return 1;
  }
  const [loadingLocation, setLoadingLocation] = useState(false);
  const handleShowclaimdrop = async () => {
    setLoadingLocation(true);
    const ip = await getIpAddress();
    const locationData = await getLocationWithIpAddress(ip);
    if (locationData) {
      setUserLocation((prev) => ({
        ...prev,
        ipaddress: ip,
        location: locationData,
      }));
      handleClose();
      setShowclaimdrop(true);
      setLoadingLocation(false);
    }
  };
  const [showthanksclaimdrop, setShowthanksclaimdrop] = useState(false);

  const handleClosethanksclaimdrop = () => {
    setShowthanksclaimdrop(false);
    resetClaimForm()
  }
  const handleShowthanksclaimdrop = (e) => {
    e.preventDefault();
    handleCloseclaimdrop();
    setShowthanksclaimdrop(true);
  };

  // Share title

  const handleShareClick = () => {
    // Check if the Web Share API is supported
    if (navigator.share) {
      navigator
        .share({
          title: item?.sections?.[0]?.title,
          text: item?.sections?.[0]?.title,
          url: window.location.href,
        })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      console.log('Web Share API not supported on this browser');
    }
  };

  return (
    <>
      <div className="home_cards_top_space pt-5">
        <div className="col-12">
          <div className="card airdrop_home_card">
            <div className="row">
              <div className="col-lg-2">
                <div>
                  <p className="offer_created_text mb-0">Offer Created</p>
                  <p className="offer_created_date">
                    {new Date(item?.createdAt).toLocaleDateString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                  </p>
                  <p className="offer_created_text mb-0">Expiry</p>
                  <p className="offer_created_remaining_days mb-0">
                    <Timer targetDate={expirationDateTime} />{' '}
                  </p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className={` h-100 ${showFullContent ? '' : 'align-items-start'}`}>
                  <div className="text-center">
                    <h5 className="airdrops_home_card_text_h mb-3 pe-lg-5">{item?.sections?.[0]?.title}</h5>
                  </div>
                  <div className={` ${showFullContent ? '' : ''}`}>
                    <p
                      className="airdrops_home_card_text_p mb-0"
                      dangerouslySetInnerHTML={{
                        __html: showFullContent ? item?.sections?.[0]?.description : item?.sections?.[0]?.description,
                      }}
                    ></p>
                    {/* <p className="airdrops_home_card_text_p mb-0" dangerouslySetInnerHTML={{ __html: showFullContent ? item?.sections?.[0]?.description : item?.sections?.[0]?.description.substring(0, 100) }}>
                      </p> */}
                    {/* {item?.sections?.[0]?.description && item?.sections?.[0]?.description.length > 100 && (
                      <div>
                        <p
                          className={`see_more_text_btn mb-0 ${showFullContent ? 'pt-0' : ''}`}
                          onClick={toggleContent}
                        >
                          {showFullContent ? 'Less...' : 'More...'}
                        </p>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div
                  className={`d-flex h-100 justify-content-lg-end justify-content-center ${showFullContent ? 'align-items-start' : 'align-items-start'
                    }`}
                >
                  {!item?.userClaim ? <button
                    className={`claim_now_btn mt-lg-0 mt-md-3 mt-3 ${isExpired ? 'claim_now_btn_disabled' : ''}`}
                    data-airdrop-attribute={item?._id}
                    onClick={(event) => {
                      handleShow(item?.sections?.[0]?.contents)
                      // Retrieve the data attribute value
                      const dataAttribute = event.target.getAttribute('data-airdrop-attribute');
                      formik.setFieldValue('airdrop', dataAttribute)
                    }}
                    disabled={isExpired}
                  >
                    Claim Now
                  </button> :
                    <button
                      className={`claim_now_btn mt-lg-0 mt-md-3 mt-3 claim_now_btn_disabled `}
                    // disabled={true}
                    >
                      {capitalizeFirstLetter(item?.userClaim?.status)}
                    </button>
                  }
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-end pb-4 pt-2">
                  <div className="wap_share_icon d-flex justify-content-center align-items-center px-2 py-1">
                    <Link to="https://www.facebook.com/ideaologytech/" target="_blank" rel="noopenner">
                      <FaFacebook fontSize={32} color="#316FF6" />
                    </Link>
                  </div>
                  <div className="wap_share_icon d-flex justify-content-center align-items-center px-2 py-2">
                    <Link to="https://t.me/ideaologyio" target="_blank" rel="noopenner">
                      <FaTelegram fontSize={32} color="#0088cc" />
                    </Link>
                  </div>
                  <div className="wap_share_icon d-flex justify-content-center align-items-center px-2 py-2">
                    <Link to="https://www.linkedin.com/company/ideaology-io/" target="_blank" rel="noopenner">
                      <FaLinkedin fontSize={32} color="#0077b5" />
                    </Link>
                  </div>
                  <div className="wap_share_icon d-flex justify-content-center align-items-center px-2 py-2">
                    <Link to="https://twitter.com/ideaologyio" target="_blank" rel="noopenner">
                      <FaTwitter fontSize={32} color="#1DA1F2" />
                    </Link>
                  </div>
                  <div className="wap_share_icon d-flex justify-content-center align-items-center px-2 py-2">
                    <Link to="https://www.instagram.com/ideaologyio" target="_blank" rel="noopenner">
                      <FaInstagram fontSize={32} color="rgb(191 47 156)" />
                    </Link>
                  </div>
                  <div className="wap_share_icon d-flex justify-content-center align-items-center pe-2 py-2">
                    <div className="share_heading_icon" onClick={handleShareClick}>
                      <RiShareFill fontSize={28} color="aqua" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Location Modal  */}

      <Modal show={show} onHide={handleClose} size="lg" centered>
        <div className="location_modal_space">
          <div className="row">
            <div className="col-3">
              <div className="d-flex align-items-center h-100 justify-content-center">
                <img src={locationiconimg} alt="location_img" className="location_img_size" />
              </div>
            </div>
            <div className="col-9">
              <div>
                <h5 className="share_live_location">Share your live Location</h5>
                <p className="shared_location_p">
                  Shared location will be safe. Company is responsible for the privacy of your location.
                </p>
                <button className="share_live_location_btn" onClick={handleShowclaimdrop}>
                  {loadingLocation ? (
                    <div className="load-icon">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  ) : (
                    'Share Live Location'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Claim Airdrop Modal  */}

      <Modal show={showclaimdrop} onHide={handleCloseclaimdrop} centered size="lg">
        <div className="claim_airdrop_space">
          <div className="text-center">
            <h5 className="airdrops_home_card_text_h">Claim Drop</h5>
            <p className="airdrops_home_card_text_p">Airdrop for subscribing Ideaology.</p>
          </div>
          <div className="px-lg-5">
            <form onSubmit={formik.handleSubmit}>
              {currentContent?.map((item, index) => {
                return (
                  <ClientFormBuilders
                    key={item?.id}
                    field={item?.fields?.[0]}
                    item={currentContent}
                    contentCount={index + 1}
                  />
                );
              })}

              {/* <ViewWalletaddress /> */}

              <div className="d-flex justify-content-center mt-2 mb-lg-5 mb-4">
                <button onClick={handleCloseclaimdrop} className="submit_airdrop_form_btn" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      {/* Thanks Airdrop Modal  */}

      <Modal show={showthanksclaimdrop} onHide={handleClosethanksclaimdrop} centered>
        <div className="claim_sumit_airdrop_space">
          <div className="text-center">
            <img src={thanksimg} alt="Thanks img" className="thanks_airdrop_img" />
          </div>
          <div className="text-center">
            <h5 className="thanks_modal_text pt-4">Thank You</h5>
            <p className="request_submit_p mb-0 px-lg-5 px-md-3 px-3">
              Thank you for being part of Ideaology airdrop, a community that empowers the blockchain world. You have
              secured 1M IDEA tokens. Let your friends know about this awesome reward!{' '}
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};
UserdashboardCard.propTypes = {
  item: PropTypes.any,
};
export default UserdashboardCard;
