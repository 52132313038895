import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import './variables/variables.css';
import Airdropdata from './pages/Airdropsdata/Airdropdata';
import CreateAirdrop from './pages/Createairdrop/CreateAirdrop';
import SignIn from './pages/Signin/Signin';
import { Toaster, resolveValue } from 'react-hot-toast';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import Allairdrops from './components/AllAirdrops/Allairdrops';
import Usersdashboard from './components/AirdropCards/Usersdashboard';
function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));

  useEffect(() => {
    setToken(localStorage.getItem('token'));
  }, []);

  function PrivateRoute({ element, ...props }) {
    return token ? (
      <Route {...props} element={element} />
    ) : (
      <Navigate to="/signin" />
    );
  }
  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff',
          },

          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      ></Toaster>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/airdrop" element={<Airdropdata />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/users-dashboard" element={<Usersdashboard />} />
          <Route path="/create-airdrop" element={<CreateAirdrop />} />
          <Route path="/edit-airdrop" element={<CreateAirdrop />} />
          <Route path="/all-airdrops" element={token ? <Allairdrops /> : <SignIn/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
