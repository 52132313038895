import React, { useEffect, useState } from 'react';
import './Airdropdata.css';
import Navbar from '../../components/Navbar/Navbar';
import Airdropdatatable from '../../components/Airdropdatatable/Airdropdatatable';
import toast from 'react-hot-toast';
import { useSignInMutation } from '../../config/Apibase';
import SignIn from '../Signin/Signin';

const Airdropdata = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [signature, setSignature] = useState(null);
  const [timestamp, setTimestamp] = useState(null);
  const [token, setToken] = useState({});

  const [signIn, data] = useSignInMutation();
  // const res = signIn();
  useEffect(() => {
    setToken(localStorage.getItem('token'));
  }, []);

  const storedtoken = localStorage.getItem('token');
  const parsedToken = JSON.parse(storedtoken);
  const accessToken = parsedToken?.access?.token;

  return (
    <div>
      {token ? (
        <>
          <Navbar />
          <Airdropdatatable />
        </>
      ) : (
        <SignIn />
      )}
    </div>
  );
};

export default Airdropdata;
