import React from 'react'
import vecterimglogo from '../../assets/images/vecter-green.png';
import vecterimgbluelogo from '../../assets/images/vecter-blue.png';
import vecterimgredlogo from '../../assets/images/vecter-red.png';
import { formatTimestamp } from './Datatable';
import handleCreateHanding from '../../helpers/dataFormHeaderBuilder';
import { useUpdateClaimMutation } from '../../config/Apibase';

const TableRows = ({ item, index, airdrop }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [selectedOption, setSelectedOption] = React.useState(item.status.charAt(0).toUpperCase() + item.status.slice(1));

    const [
        updateClaim, // Renamed from signup to follow conventional naming
        { error: error, data: data, isSuccess: success },
    ] = useUpdateClaimMutation();

    function findValueByKey(array, key) {
        if (array === null) {
            return null; // or handle the case appropriately
        }

        const value = array.find(c => c?.question === key)?.value
        return value

        // for (let i = 0; i < array.length; i++) {
        //     const obj = array[i];
        //     if (obj !== null && typeof obj === 'object' && key in obj) {
        //         return obj[key];
        //     }
        // }
        // return null; // Key not found
    }


    const handleSelect = (option) => {
        setSelectedOption(option);
        updateClaim({ status: option.toLowerCase(), id: item._id });
        setIsOpen(false)
    };


    return (
        <tr key={index}>
            <td className="columns_top_space_th">
                <span className="airdrop_table_p"> {item?.country} </span>
            </td>
            <td className="columns_top_space_th">
                <span className="airdrop_table_p"> {item?.ipaddress} </span>
            </td>
            <td className="columns_top_space_th">
                <span className="airdrop_table_p"> {formatTimestamp(item?.createdAt).date}</span>
            </td>
            <td className="columns_top_space_th">
                <span className="airdrop_table_p">{formatTimestamp(item?.createdAt).time}</span>
            </td>

            {handleCreateHanding(airdrop)?.map((d, i) => {
                const sections = item?.sections?.[0];
                if (!sections) {
                    return null; // Handle the case where sections is undefined
                }

                const value = findValueByKey(sections, d) || "";

                return (
                    <td className="columns_top_space_th" key={d}>
                        <span className="airdrop_table_p">{value}</span>
                    </td>
                );
            })}

            <td className="columns_top_space_th">
                <span className="airdrop_table_p">{item?.walletAddress}</span>
            </td>
            <td className="columns_top_space_th text-center">
                <span className="comment_img_bg_color">
                    <div className={`custom-select-wrapper ${isOpen ? 'open' : ''}`}>
                        <div className={`custom-select-header ${selectedOption.toLowerCase()}`} onClick={() => setIsOpen(!isOpen)}>
                            {selectedOption || 'Select an option'}
                            {selectedOption && (
                                <img src={selectedOption === 'Approved' ? vecterimglogo : vecterimgbluelogo} className="ps-2" alt="" />
                            )}
                        </div>
                        {isOpen && (
                            <ul className="custom-select-options">
                                <li className="approved" onClick={() => handleSelect('Approved')}>
                                    Approved <img src={vecterimglogo} className="ps-2" alt="" />
                                </li>
                                <li className="pending" onClick={() => handleSelect('Pending')}>
                                    Pending <img src={vecterimgbluelogo} className="ps-2" alt="" />
                                </li>
                                <li className="rejected" onClick={() => handleSelect('Rejected')}>
                                    Rejected <img src={vecterimgredlogo} className="ps-2" alt="" />
                                </li>
                            </ul>
                        )}
                    </div>
                </span>
            </td>
        </tr>
    )
}

export default TableRows
