// const handleCreateHanding = (headingdata) => {
//   const heading = [];
//   headingdata?.claims?.forEach((element) => {
//     element?.sections?.forEach((section) => {
//       section.forEach((item) => {
//         if (item) {
//           const keys = Object.keys(item)?.[0]
//           if (!heading.includes(keys)) {
//             heading.push(keys);
//           }
//         }
//       });
//     });
//   });
//   return heading;
// };

const handleCreateHanding = (headingdata) => {
  const heading = [];
  headingdata?.claims?.forEach((element) => {
    element?.sections?.forEach((section) => {
      section.forEach((item) => {
        if (item) {
          // const keys = Object.keys(item)?.[0]
          if (!heading.includes(item?.question)) {
            heading.push(item?.question)
          }
        }
      });
    });
  });
  return heading;
};

export default handleCreateHanding;
