import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Timer from '../AirdropCards/Timer'
import { useDeleteAirdropFormMutation } from '../../config/Apibase';
import toast from 'react-hot-toast';
import { Modal } from 'react-bootstrap';

const Allairdropsrow = ({ item }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [deleteAirdrop, { isSuccess, isError }] = useDeleteAirdropFormMutation();

  const handleDeleteAirdrop = (id) => {
    deleteAirdrop(id)
      .then((response) => {
        toast.success('Successfully deleted!');
      })
      .catch((error) => {
        toast.error('Sorry your Airdrop is not deleted!');
      });
  }

  return (
    <>
      <tr className="">
        <td className="columns_top_space_th">
          <span className="airdrop_table_p"> {item?.id} </span>
        </td>
        <td className="columns_top_space_th">
          <span className="airdrop_table_p"> {item?.Airdropname} </span>
        </td>
        <td className="columns_top_space_th">
          <span className="airdrop_table_p"> {item.date}</span>
        </td>
        <td className="columns_top_space_th">
          <span className="airdrop_table_p"><Timer targetDate={item?.expirationDateTime} /> </span>
        </td>
        <td className="columns_top_space_th">
          <Link to='/edit-airdrop' state={{ airdropId: item?.id }} className="airdrop_table_edit_btn">Edit</Link>
        </td>
        <td className="columns_top_space_th">
          <Link to="#" className="airdrop_table_edit_btn" onClick={() => handleDeleteAirdrop(item?.id)}>Delete</Link>
        </td>
      </tr>

      <Modal show={show} onHide={handleClose} centered>
        <div className="px-lg-5 px-md-4 px-3" id="#deletemodalstyle">
          <div className="">
            <div className="">
              <div className="text-center">
                <h4 className="are_you_sure w-100 pt-4">Are you sure?</h4>
              </div>
              <div className="text-center">
                <button type="button" className="cross_btn">
                  &times;
                </button>
              </div>
            </div>
            <div className="py-3">
              <p className="confirmation_p">
                Do you really want to delete these records? This process cannot be undone.
              </p>
            </div>
            <div className="d-flex justify-content-center pb-4">
              <button type="button" className="btn btn-secondary me-2" onClick={handleClose}>
                Cancel
              </button>
              <button type="button" className="btn btn-danger">
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Allairdropsrow;
