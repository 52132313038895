import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { api } from '../config/Apibase';
import { persistStore, persistReducer } from 'redux-persist';
import { setupListeners } from '@reduxjs/toolkit/query';
import storage from 'redux-persist/lib/storage';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
const rootReducer = combineReducers({
  //   auth: '',
  [api.reducerPath]: api.reducer,
});
const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth'], // Example: Only persist the 'auth' slice
};
const persistedReducer = persistReducer(persistConfig, rootReducer); // Apply persistence
// const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware),
});

setupListeners(store.dispatch);
const persistor = persistStore(store);

export { persistor, store };
